@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DesarquivadosRegular";
  /*Can be any text*/
  src:
    local("DesarquivadosRegular"),
    url("./assets/fonts/DesarquivadosRegular.ttf") format("truetype");
}

@font-face {
  font-family: "BAHNSCHRIFT";
  src:
    local("BAHNSCHRIFT"),
    url("./assets/fonts/BAHNSCHRIFT.TTF") format("truetype");
}
